import React, { useEffect, useState } from 'react'
import { Auth, API } from 'aws-amplify'
import config from 'config'

const AuthContext = React.createContext()
const WithAuth = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [authenticating, setAuthenticating] = useState(true)
  const [user, setUser] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authUser = await Auth.currentAuthenticatedUser()
        const user = await API.get('users', `/${authUser.username}`)
        if (!config.USER_ROLES.includes(user.role)) {
          Auth.signOut()
          throw new Error('Invalid user role')
        }
        setUser(user)
        setAuthenticated(true)
        setAuthenticating(false)
      } catch (e) {
        setAuthenticated(false)
        setAuthenticating(false)
      }
    }

    fetchData()
  }, [])

  const defaultContext = {
    authenticating,
    authenticated,
    setAuthenticated,
    user,
    setUser,
  }
  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>
}

export { AuthContext }
export default WithAuth
