import React from 'react'
import styles from './Deprecated.module.scss'

function Deprecated() {
  return (
    <div className={styles.login}>
      <div className={styles.header}>Be So Social</div>
      <div className={styles.body}>
        <a href='https://app.beso.social'>Go to login</a>
      </div>
    </div>
  )
}

export default Deprecated
