import {
  faBars,
  faChevronDown,
  faChevronRight,
  faLightbulb,
  faList,
  faUserCog,
  faUserLock,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Sidenav.module.scss'

function Sidenav() {
  const [expanded, setExpanded] = useState(false)
  const [users, setUsers] = useState(false)
  const handleToggle = () => setExpanded(!expanded)
  const handleClick = () => setExpanded(false)
  const handleUsers = () => setUsers(!users)

  return (
    <div className={styles.sidenav}>
      <div className={styles.topPanel}>
        <div className={styles.toggle} onClick={handleToggle}>
          <FontAwesomeIcon className={styles.menuIcon} icon={faBars} />
        </div>
        <div className={styles.logo}>
          <NavLink to='/' exact onClick={handleClick}>
            Be So Social
            <br />
            Admin
          </NavLink>
        </div>
      </div>

      <div className={styles.panel}>
        <div className={`${styles.items} ${expanded ? styles.expanded : ''}`}>
          <NavLink
            className={styles.link}
            to='/categories'
            exact
            activeClassName={styles.active}
            onClick={handleClick}>
            <FontAwesomeIcon className={styles.icon} icon={faList} />
            Categories
            <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
          </NavLink>
          <NavLink
            className={styles.link}
            to='/social-tips'
            exact
            activeClassName={styles.active}
            onClick={handleClick}>
            <FontAwesomeIcon className={styles.icon} icon={faLightbulb} />
            Social Tips
            <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
          </NavLink>
          <div className={styles.link} onClick={handleUsers}>
            <FontAwesomeIcon className={styles.icon} icon={faUsers} />
            Users
            <FontAwesomeIcon
              className={styles.rightIcon}
              icon={users ? faChevronDown : faChevronRight}
            />
          </div>
          <div className={`${styles.subItems} ${users ? styles.expanded : ''}`}>
            <NavLink
              className={styles.link}
              to='/admins'
              exact
              activeClassName={styles.active}
              onClick={handleClick}>
              <FontAwesomeIcon className={styles.icon} icon={faUserLock} />
              Admins
              <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
            </NavLink>
            <NavLink
              className={styles.link}
              to='/distributors'
              exact
              activeClassName={styles.active}
              onClick={handleClick}>
              <FontAwesomeIcon className={styles.icon} icon={faUserCog} />
              Distributors
              <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidenav
