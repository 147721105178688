import React from 'react'
import { NavLink } from 'react-router-dom'
import DropdownMenu from 'components/drop-down/DropdownMenu'
import DropdownItem from 'components/drop-down/DropdownItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import styles from './Topnav.module.scss'

function Topnav() {
  return (
    <div className={styles.header}>
      <div className={styles.actions}>
        <DropdownMenu
          title={<FontAwesomeIcon className={styles.icon} icon={faUserCircle} />}>
          <NavLink to='/logout' exact>
            <DropdownItem>Logout</DropdownItem>
          </NavLink>
        </DropdownMenu>
      </div>
    </div>
  )
}

export default Topnav
