import React from 'react'
import Sidenav from './Sidenav'
import Topnav from './Topnav'

function Nav() {
  return (
    <div>
      <Sidenav />
      <Topnav />
    </div>
  )
}

export default Nav
