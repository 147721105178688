import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import Routes from './Routes'
import { AuthContext } from './contexts/AuthContext'
import Nav from 'components/nav/Nav'
import styles from './App.module.scss'

function App() {
  const { authenticated } = useContext(AuthContext)

  return (
    <div>
      {authenticated && <Nav />}
      <div className={`${styles.container} ${authenticated ? styles.auth : ''}`}>
        <Routes />
      </div>
    </div>
  )
}

export default withRouter(App)
