const dev = {
  s3: {
    REGION: "eu-west-2",
    BUCKET: "be-so-social-infrastructure-posts-dev-mediabucket-i2tpqnktcxiu"
  },
  apis: {
    posts: {
      REGION: 'eu-west-2',
      URL: 'https://sf16h4n4s5.execute-api.eu-west-2.amazonaws.com/dev/v1',
    },
    categories: {
      REGION: 'eu-west-2',
      URL: 'https://f72ma7w0z5.execute-api.eu-west-2.amazonaws.com/dev/v1',
    },
    schedule: {
      REGION: 'eu-west-2',
      URL: 'https://qwpv6oei26.execute-api.eu-west-2.amazonaws.com/dev/v1',
    },
    tokens: {
      REGION: 'eu-west-2',
      URL: 'https://4pahpixra8.execute-api.eu-west-2.amazonaws.com/dev/v1/tokens',
    },
    tips: {
      REGION: 'eu-west-2',
      URL: 'https://t00lgtyjv3.execute-api.eu-west-2.amazonaws.com/dev/v1',
    },
    users: {
      REGION: 'eu-west-2',
      URL: 'https://t3jxbbhacg.execute-api.eu-west-2.amazonaws.com/dev/v1/users',
    },
    admins: {
      REGION: 'eu-west-2',
      URL: 'https://t3jxbbhacg.execute-api.eu-west-2.amazonaws.com/dev/v1/admins',
    },
    distributors: {
      REGION: 'eu-west-2',
      URL: 'https://t3jxbbhacg.execute-api.eu-west-2.amazonaws.com/dev/v1/distributors',
    },
  },
  cognito: {
    REGION: 'eu-west-2',
    USER_POOL_ID: 'eu-west-2_yPDpPutAO',
    APP_CLIENT_ID: 'jeu0dgs3pb0q1v5k9ks1j8e0n',
    IDENTITY_POOL_ID: 'eu-west-2:ee88a555-d175-4bca-9bb0-ebf1b0990285',
  },
}

const prod = {
  s3: {
    REGION: "eu-west-2",
    BUCKET: "be-so-social-infrastructure-posts-pro-mediabucket-1sniqcmo115my"
  },
  apis: {
    posts: {
      REGION: 'eu-west-2',
      URL: 'https://vqskezzfqa.execute-api.eu-west-2.amazonaws.com/prod/v1',
    },
    categories: {
      REGION: 'eu-west-2',
      URL: 'https://arid58lmya.execute-api.eu-west-2.amazonaws.com/prod/v1',
    },
    schedule: {
      REGION: 'eu-west-2',
      URL: 'https://tfup0ouvqk.execute-api.eu-west-2.amazonaws.com/prod/v1',
    },
    tokens: {
      REGION: 'eu-west-2',
      URL: 'https://1qn4f59x01.execute-api.eu-west-2.amazonaws.com/prod/v1/tokens',
      },
    tips: {
      REGION: 'eu-west-2',
      URL: 'https://x8hi7e28md.execute-api.eu-west-2.amazonaws.com/prod/v1',
    },
    users: {
      REGION: 'eu-west-2',
      URL: 'https://vp5viah406.execute-api.eu-west-2.amazonaws.com/prod/v1/users',
    },
    admins: {
      REGION: 'eu-west-2',
      URL: 'https://vp5viah406.execute-api.eu-west-2.amazonaws.com/prod/v1/admins',
    },
    distributors: {
      REGION: 'eu-west-2',
      URL: 'https://vp5viah406.execute-api.eu-west-2.amazonaws.com/prod/v1/distributors',
    }
  },
  cognito: {
    REGION: 'eu-west-2',
    USER_POOL_ID: 'eu-west-2_AsVPLIDz6',
    APP_CLIENT_ID: '2j6qsvtk12ou5ogbke6o3rjehj',
    IDENTITY_POOL_ID: 'eu-west-2:1bbc6bff-1fab-41db-8a07-f4f7889602f3',
  },
}

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev

export default {
  // Add common config values here
  USER_ROLES: ['admin'],
  POST_IMG_MAX_SIZE: 5000000, // 5mb
  POST_IMG_TYPES: ['image/jpeg', 'image/png'], // 5mb
  ...config,
}
