import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import AuthContext from './contexts/AuthContext'
import * as serviceWorker from './serviceWorker'
import config from './config'
import './index.scss'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: 'categories',
        endpoint: config.apis.categories.URL,
        region: config.apis.categories.REGION,
      },
      {
        name: 'posts',
        endpoint: config.apis.posts.URL,
        region: config.apis.posts.REGION,
      },
      {
        name: 'tips',
        endpoint: config.apis.tips.URL,
        region: config.apis.tips.REGION,
      },
      {
        name: 'users',
        endpoint: config.apis.users.URL,
        region: config.apis.users.REGION,
      },
      {
        name: 'admins',
        endpoint: config.apis.admins.URL,
        region: config.apis.admins.REGION,
      },
      {
        name: 'distributors',
        endpoint: config.apis.distributors.URL,
        region: config.apis.distributors.REGION,
      }
    ],
  },
})

ReactDOM.render(
  <Router>
    <AuthContext>
      <App />
    </AuthContext>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
