import React, { useState } from 'react'
import styles from './DropdownMenu.module.scss'

function DropdownMenu(props) {
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div className={styles.container}>
      <div className={styles.title} onClick={handleClick}>
        {props.title}
      </div>
      <div className={`${styles.items} ${expanded ? styles.expanded : ''}`}>
        {props.children}
      </div>
    </div>
  )
}

export default DropdownMenu
